import { Link } from "gatsby"
import React, { useState } from "react"
import { useTheme } from "emotion-theming"
import { mq } from "../theme"
import { ClassNames } from "@emotion/core"
import Tooltip from "rc-tooltip"
import Expand from "react-expand-animated"
import "rc-tooltip/assets/bootstrap_white.css"
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons"

export const MNavLink = ({ label, to, sub, blockRedirect, closeDrawer }) => {
  const hasSubs = sub && sub.length > 0
  const [open, setOpen] = useState(false)
  const path = window.location.pathname + window.location.hash
  return (
    <>
      <ClassNames>
        {({ css }) => (
          <>
            <Link
              className={css`
                box-sizing: border-box;
                padding: 10px 20px;
                color: white;
                font-family: ProximaNova-Bold;
                display: flex;
                align-items: center;
                justify-content: space-between;
                :hover {
                  background-color: rgb(0, 0, 0, 0.1);
                  color: white;
                }
              `}
              onClick={e => {
                if (hasSubs || blockRedirect) {
                  e.preventDefault()
                } else {
                  requestAnimationFrame(() => {
                    closeDrawer()
                  })
                }
                if (hasSubs) {
                  setOpen(x => !x)
                }
              }}
              activeClassName={css`
                background-color: rgb(0, 0, 0, 0.1);
              `}
              to={to}
            >
              <span>{label}</span>{" "}
              {hasSubs ? (
                open ? (
                  <MinusCircleOutlined
                    className={css`
                      color: white;
                      font-size: 14px;
                    `}
                  />
                ) : (
                  <PlusCircleOutlined
                    className={css`
                      color: white;
                      font-size: 14px;
                    `}
                  />
                )
              ) : null}
            </Link>
            {hasSubs ? (
              <Expand
                className={css`
                  width: 100%;
                  box-sizing: border-box;
                  display: flex;
                  flex-direction: column;
                `}
                open={open}
              >
                {sub.map(({ label, to }, i) => {
                  return (
                    <Link
                      key={i}
                      className={css`
                        box-sizing: border-box;
                        padding: 10px 0px 10px 40px;
                        color: white;
                        width: 100%;
                        flex: 1;
                        font-family: ProximaNova-Bold;
                        text-transform: uppercase;
                        :hover {
                          background-color: rgb(0, 0, 0, 0.1);
                          color: white;
                        }
                        ${to === path
                          ? "background-color: rgb(0, 0, 0, 0.1);"
                          : ""}
                      `}
                      activeClassName={css`
                        background-color: rgb(0, 0, 0, 0.1);
                      `}
                      to={to}
                      onClick={() => {
                        closeDrawer()
                      }}
                    >
                      {label}
                    </Link>
                  )
                })}
              </Expand>
            ) : null}
          </>
        )}
      </ClassNames>
    </>
  )
}

// TODO!: next time here is the dropdown do it in projects
// TODO!: have a single source of truth object mapping of routes in nav
export const DNavLink = ({ label, to, sub, blockRedirect }) => {
  const {
    colors: {
      txt: { link },
    },
  } = useTheme()

  return !(sub && sub.length > 0) ? (
    <ClassNames>
      {({ css }) => (
        <Link
          className={css`
            color: black;
            font-family: ProximaNova-Bold;
            font-size: 12px;
            box-sizing: border-box;
            margin: 0px 15px;
            text-align: center;
            display: none;
            ${mq.tablet} {
              display: initial;
            }
          `}
          activeClassName={css`
            color: ${link};
          `}
          to={to}
        >
          {label}
        </Link>
      )}
    </ClassNames>
  ) : (
    <ClassNames>
      {({ css }) => (
        <Tooltip
          placement="bottom"
          trigger={["hover"]}
          overlay={
            <>
              {(sub || []).map(({ to, label }, i) => (
                <Link
                  key={i}
                  className={css`
                    text-transform: uppercase;
                    font-family: ProximaNova-Bold;
                    font-size: 10px;
                    box-sizing: border-box;
                    padding: 3px 15px;
                    text-align: center;
                    color: white;
                    display: none;
                    ${mq.tablet} {
                      display: initial;
                    }
                    :hover {
                      background-color: rgb(0, 0, 0, 0.1);
                      color: white;
                    }
                  `}
                  activeClassName={css`
                    background-color: rgb(0, 0, 0, 0.1);
                  `}
                  to={to}
                >
                  {label}
                </Link>
              ))}
            </>
          }
          overlayClassName={css`
            background-color: ${link};
            z-index: 1000000000000;
            border-radius: 5px;
            & .rc-tooltip-content .rc-tooltip-arrow {
              top: -9px;
              margin-left: -10px;
              border-width: 0 10px 10px;
              border-bottom-color: ${link};
            }
            & .rc-tooltip-content .rc-tooltip-inner {
              border-radius: 5px;
              background-color: ${link};
              padding: 8px 0px;
              border: none;
              display: flex;
              flex-direction: column;
            }
          `}
        >
          <Link
            className={css`
              color: black;
              font-family: ProximaNova-Bold;
              font-size: 12px;
              box-sizing: border-box;
              margin: 0px 15px;
              text-align: center;
              display: none;
              ${mq.tablet} {
                display: initial;
              }
            `}
            activeClassName={css`
              color: ${link};
            `}
            to={to}
            onClick={e => {
              if (blockRedirect) {
                e.preventDefault()
              }
            }}
          >
            {label}
          </Link>
        </Tooltip>
      )}
    </ClassNames>
  )
}
