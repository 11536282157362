export const theme = {
  colors: {
    bg: {
      white: "#fff",
      black: "#000",
      lightGrey: "#f5f5f5",
      grey: "#ddd",
      primary: "#18386a",
    },
    txt: {
      darkGrey: "#171717",
      mediumGrey: "#656565",
      white: "#fff",
      link: "#0074e5",
    },
  },
}

export const breakpoints = {
  mobile: 577,
  tablet: 769,
  desktop: 992,
  lgDesktop: 1200,
}

export const mq = Object.keys(breakpoints).reduce((acc, key) => {
  acc[key] = `@media (min-width: ${breakpoints[key]}px)`
  return acc
}, {})
