import React from "react"
import { useStaticQuery, Link, graphql } from "gatsby"
import { useTheme } from "emotion-theming"
import { css, ClassNames } from "@emotion/core"
import { mq, breakpoints } from "./theme"
import styled from "@emotion/styled"
import Img from "gatsby-image"

const ColumnLinks = ({ header, links, className }) => {
  const {
    colors: {
      txt: { link },
    },
  } = useTheme()
  return (
    <div
      className={className}
      css={css`
        display: flex;
        flex-direction: column;
      `}
    >
      <h6
        css={css`
          color: ${link};
          font-family: ProximaNova-Bold;
          font-size: 16px;
          letter-spacing: 1px;
          margin-bottom: 0px;
        `}
      >
        {header}
      </h6>
      <ClassNames>
        {({ css }) =>
          links.map(({ to, label, extern }) => {
            const className = css`
              font-size: 12px;
              margin-top: 7px;
              margin-bottom: 0px;
              color: black;
              font-family: ProximaNova-Semibold;
            `
            if (extern) {
              return (
                <a className={className} href={to} key={to}>
                  {label}
                </a>
              )
            }
            return (
              <Link className={className} key={to} to={to}>
                {label}
              </Link>
            )
          })
        }
      </ClassNames>
    </div>
  )
}

const P = styled.p`
  font-size: 12px;
  /* line-height: 5px; */
  margin-top: ${({ top }) => (top ? "0px" : "7px")};
  color: black;
  font-family: ProximaNova-Semibold;
  margin-bottom: 0px;
`

const AddressColumn = () => {
  return (
    <div
      css={css`
        display: none;
        ${mq.mobile} {
          display: initial;
          flex: 1;
        }
      `}
    >
      <P top>
        Unit 506-507 West Tower, Philippine Stock Exchange Centre, Exchange
        Road, Ortigas Center, Pasig City, Philippines
      </P>
      <P>hello@fsvrc.com</P>
      <P>(+632) 9706-1529</P>
      <P>(+632) 9706-1562</P>
    </div>
  )
}

const bottomText = css`
  color: white;
  margin: 0;
  font-size: 12px;
  margin-right: 5px;
  letter-spacing: 1px;

  font-family: ProximaNova-Semibold;
`

const Footer = () => {
  const { logoW } = useStaticQuery(graphql`
    query {
      logoW: file(relativePath: { eq: "fsvrc-logo-only-white.png" }) {
        childImageSharp {
          fixed(width: 35) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  const {
    colors: {
      bg: { grey, primary },
    },
  } = useTheme()
  return (
    <footer css={css`
      flex-shrink: 0;
    `}>
      <div
        css={css`
          background: ${grey};
        `}
      >
        <div
          css={css`
            display: flex;
            max-width: 900px;
            margin: 0 auto;
            padding: 20px 35px;
            ${mq.mobile} {
              padding: 20px 50px;
            }
            ${mq.tablet} {
            }
            ${mq.desktop} {
              max-width: ${breakpoints.desktop}px;
            }
            ${mq.lgDesktop} {
              max-width: ${breakpoints.lgDesktop}px;
            }
          `}
        >
          <ColumnLinks
            css={css`
              flex: 1;
            `}
            header="CONNECT"
            links={[
              { label: "About", to: "/about" },
              { label: "Contact Us", to: "/contact" },
              { label: "Careers", to: "/careers" },
            ]}
          />
          <ColumnLinks
            css={css`
              flex: 1;
            `}
            header="FOLLOW US"
            links={[
              {
                label: "Facebook",
                to:
                  "https://www.facebook.com/FirstShelterVenturesRealtyCorporation",
                extern: true,
              },
              {
                label: "Instagram",
                to: "https://www.instagram.com/fsvrcph/",
                extern: true,
              },
            ]}
          />
          <AddressColumn />
        </div>
      </div>
      <div
        css={css`
          background: ${primary};
        `}
      >
        <div
          css={css`
            display: flex;
            width: 100%;
            padding: 20px 35px;
            align-items: center;
          `}
        >
          <div
            css={css`
              display: none;
              margin-right: 20px;
              ${mq.mobile} {
                display: flex;
                align-items: center;
              }
            `}
          >
            <Img fixed={logoW.childImageSharp.fixed} />
          </div>
          <div
            css={css`
              margin-right: 20px;
              ${mq.mobile} {
                margin-right: 30px;
              }
            `}
          >
            <p css={bottomText}>© 2020 ALL RIGHTS RESERVED</p>
          </div>
          <ClassNames>
            {({ css }) => {
              const className = css`
                color: white;
                margin-right: 30px;
                font-size: 12px;
                letter-spacing: 1px;
                font-family: ProximaNova-Semibold;
              `
              const hideCss = css`
                display: none;
                ${mq.mobile} {
                  display: initial;
                }
              `
              return (
                <>
                  <Link className={className} to="/terms">
                    TERMS <span css={hideCss}>OF USE </span>
                  </Link>
                  <Link className={className} to="/privacy">
                    PRIVACY <span css={hideCss}>POLICY </span>
                  </Link>
                </>
              )
            }}
          </ClassNames>
        </div>
      </div>
    </footer>
  )
}

export default Footer
