/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { ThemeProvider } from "emotion-theming"
import { useStaticQuery, graphql } from "gatsby"
import { Context as ResponsiveContext } from "react-responsive"
import Header from "./header"
import Footer from "./footer"
import "./layout.css"
import "antd/dist/antd.css"
import { breakpoints, theme } from "./theme"
import { css } from "@emotion/core"
import SimpleReactLightbox from "simple-react-lightbox"
import smoothscroll from "smoothscroll-polyfill"

const isBrowser = () => typeof window !== "undefined"
if (isBrowser()) {
  smoothscroll.polyfill()
}

const Main = ({ children }) => {
  return (
    <main
      css={css`
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
      `}
    >
      {children}
    </main>
  )
}

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [initialized, setInitialized] = useState(false)
  useEffect(() => {
    setInitialized(true)
  }, [])

  return (
    <>
      <ThemeProvider theme={theme}>
        <SimpleReactLightbox>
          <Header siteTitle={data.site.siteMetadata.title} />
          {initialized ? (
            <Main>{children}</Main>
          ) : (
            <ResponsiveContext.Provider value={{ width: breakpoints.desktop }}>
              <Main>{children}</Main>
            </ResponsiveContext.Provider>
          )}
          <Footer />
        </SimpleReactLightbox>
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
