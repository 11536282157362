import { CloseCircleOutlined, MenuOutlined } from "@ant-design/icons"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import { Drawer } from "antd"
import { useTheme } from "emotion-theming"
import { graphql, Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import { DNavLink, MNavLink } from "./components/nav-links"
import { mq } from "./theme"

const header = css({
  display: "flex",
  alignItems: "center",
  zIndex: 10000.0,
  padding: "10px 20px",
  position: "sticky",
  top: 0,
  background: "white",
  [mq.tablet]: {
    padding: "10px 40px",
  },
})

const MenuOutlinedS = styled(MenuOutlined)(({ theme }) => ({
  fontSize: "18px",
  color: theme.colors.bg.primary,
}))

const CloseCircleOutlinedS = styled(CloseCircleOutlined)({
  color: "white",
  fontSize: "18px",
})

const DrawerS = styled(Drawer)`
  ${mq.tablet} {
    display: none;
  }
`

const navLinks = [
  {
    label: "HOME",
    to: "/",
  },
  {
    label: "ABOUT",
    to: "/about/",
    sub: [
      {
        label: "HISTORY",
        to: "/about#history",
      },
      { label: "VISION & MISSION", to: "/about#vision-mission" },
    ],
  },
  {
    label: "PROJECTS",
    to: "/projects",
    blockRedirect: true,
    sub: [
      { label: "Serrata Homes", to: "/projects/serrata-homes" },
      { label: "Bloomfield 1A & 1B", to: "/projects/bloomfield-1a-1b" },
      { label: "Alsea", to: "/projects/alsea" },
      { label: "Bloomfield Park", to: "/projects/bloomfield-park" },
      { label: "Bloomfield Resort", to: "/projects/bloomfield-resort" },
      { label: "Avilion Gardens", to: "/projects/avilion-gardens" },
      { label: "Altiplano", to: "/projects/altiplano" },
      { label: "Bloomfield Aqua", to: "/projects/bloomfield-aqua" },
      { label: "Bloomfield Enclave", to: "/projects/bloomfield-enclave" },
    ],
  },
  // {
  //   label: "DOWNLOADS",
  //   to: "/downloads",
  //   blockRedirect: true,
  //   sub: [
  //     { label: "Bloomfield Resort", to: "/downloads/bloomfield-resort" },
  //     {
  //       label: "Avilion Gardens",
  //       to: "/downloads/avilion-gardens",
  //     },
  //     {
  //       label: "Altiplano",
  //       to: "/downloads/altiplano",
  //     },
  //   ],
  // },
  {
    label: "CONTACT",
    to: "/contact",
  },
]

const Header = ({ siteTitle }) => {
  const { file } = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "fsvrc-logo.png" }) {
          childImageSharp {
            fixed(width: 200) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )
  const [drawerVisible, setDrawerVisible] = React.useState(false)
  const theme = useTheme()

  return (
    <>
      <header css={header}>
        <div
          css={css`
            flex: 1;
            ${mq.tablet} {
              display: none;
            }
          `}
        >
          <MenuOutlinedS onClick={() => setDrawerVisible(b => !b)} />
        </div>
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 3;
            ${mq.tablet} {
              justify-content: flex-start;
              flex: 1;
            }
          `}
        >
          <Link to="/">
            <Img fixed={file.childImageSharp.fixed} />
          </Link>
        </div>
        <div
          css={css`
            flex: 1;
            ${mq.tablet} {
              display: flex;
              justify-content: flex-end;
              flex: 3;
            }
          `}
        >
          {navLinks.map(link => {
            return <DNavLink key={link.label} {...link} />
          })}
        </div>
      </header>
      <DrawerS
        visible={drawerVisible}
        placement="left"
        closable={false}
        onClose={() => setDrawerVisible(false)}
        bodyStyle={{
          backgroundColor: theme.colors.txt.link,
          display: "flex",
          flexDirection: "column",
          padding: "0px",
        }}
        drawerStyle={{ padding: "60px 0px 0px 0px" }}
      >
        <div
          css={css`
            display: flex;
            justify-content: flex-end;
            margin: 20px;
            box-sizing: border-box;
          `}
        >
          <CloseCircleOutlinedS onClick={() => setDrawerVisible(false)} />
        </div>
        {navLinks.map((link, i) => {
          return (
            <MNavLink
              closeDrawer={() => setDrawerVisible(false)}
              key={i}
              {...link}
            />
          )
        })}
      </DrawerS>
    </>
  )
}

export default Header
